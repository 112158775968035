import React, { useEffect, useState } from 'react';

const allSubjects = ['He', 'She', 'I', 'You', 'We', 'They'];
const allVerbs = ['like', 'likes'];
const allObjects = ['apples', 'banana', 'fruits', 'cake', 'pizza', 'books', 'movies', 'music', 'games', 'cats', 'dogs'];

const ActivityArea: React.FC = () => {
  const [sentence, setSentence] = useState<string[]>([]);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60);
  const [gameOver, setGameOver] = useState(false);
  const [message, setMessage] = useState('');
  const [availableWords, setAvailableWords] = useState<string[]>([
    ...getRandomWords(allSubjects, 3),
    ...getRandomWords(allVerbs, 2),
    ...getRandomWords(allObjects, 7)
  ]);

  function getRandomWords(wordList: string[], count: number): string[] {
    return [...wordList].sort(() => 0.5 - Math.random()).slice(0, count);
  }

  function shuffleArray<T>(array: T[]): T[] {
    return [...array].sort(() => Math.random() - 0.5);
  }

  useEffect(() => {
    if (timeLeft > 0 && !gameOver) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
      setGameOver(true);
    }
  }, [timeLeft, gameOver]);

  const addWord = (word: string, type: 'subject' | 'verb' | 'object') => {
    const newSentence = [...sentence, word];
    setSentence(newSentence);
    
    if (newSentence.length === 3) {
      if (isValidSentence(newSentence)) {
        setScore(score + 1);
        setMessage('Great job!');
        
        // Replace used words
        replaceUsedWords(newSentence);
      } else {
        setMessage('The sentence is invalid!');
      }
      setSentence([]);
    }
  };

  const replaceUsedWords = (usedWords: string[]) => {
    setAvailableWords(prev => {
      const newWords = shuffleArray(usedWords.map(usedWord => {
        let allWords: string[];
        if (allSubjects.includes(usedWord)) allWords = allSubjects;
        else if (allVerbs.includes(usedWord)) allWords = allVerbs;
        else allWords = allObjects;

        const availableWords = allWords.filter(word => !prev.includes(word) || word === usedWord);
        return availableWords.length > 0
          ? availableWords[Math.floor(Math.random() * availableWords.length)]
          : allWords[Math.floor(Math.random() * allWords.length)];
      }));

      return prev.map(word => usedWords.includes(word) ? newWords.pop()! : word);
    });
  };

  const isValidSentence = (sent: string[]): boolean => {
    if (sent.length !== 3) return false;
    
    const [subject, verb, object] = sent;
    
    if (!allSubjects.includes(subject)) return false;
    if (!allVerbs.includes(verb)) return false;
    if (!allObjects.includes(object)) return false;

    // Check subject-verb agreement
    if ((subject === 'He' || subject === 'She') && verb !== 'likes') return false;
    if ((subject === 'I' || subject === 'You' || subject === 'We' || subject === 'They') && verb !== 'like') return false;

    return true;
  };

  const resetGame = () => {
    setSentence([]);
    setScore(0);
    setTimeLeft(60);
    setGameOver(false);
    setMessage('');
  };

  return (
    <div className="activity-area">
      <h2>Sentence Making Game</h2>
      <div>Time Left: {timeLeft} seconds</div>
      <div>Score: {score}</div>
      <div className="sentence-message-container">
        <div className="sentence-area">
          {sentence.join(' ')}
        </div>
        <div className="message-area">
          {message && <div className="message">{message}</div>}
        </div>
      </div>
      <div className="word-buttons">
        {availableWords.map((word, index) => {
          let wordType: 'subject' | 'verb' | 'object';
          if (allSubjects.includes(word)) wordType = 'subject';
          else if (allVerbs.includes(word)) wordType = 'verb';
          else wordType = 'object';

          return (
            <button 
              key={`word-${index}`} 
              onClick={() => addWord(word, wordType)} 
              disabled={gameOver}
            >
              {word}
            </button>
          );
        })}
      </div>
      {gameOver && (
        <div>
          <h3>Game Over!</h3>
          <p>Your final score: {score}</p>
          <button onClick={resetGame}>Play Again</button>
        </div>
      )}
    </div>
  );
};

export default ActivityArea;
