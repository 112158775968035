import React, { useState } from 'react';

interface Message {
  sender: string;
  text: string;
}

const ChatArea: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([
    { sender: 'Lia', text: 'Hi there! How can I help you today?' },
    { sender: 'You', text: 'Hello Lia! I have a question about the activity.' },
    { sender: 'Lia', text: 'Sure, what would you like to know?' },
  ]);
  const [newMessage, setNewMessage] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newMessage.trim()) {
      setMessages([...messages, { sender: 'You', text: newMessage.trim() }]);
      setNewMessage('');
    }
  };

  return (
    <div className="chat-area">
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender.toLowerCase()}`}>
            <strong>{message.sender}:</strong> {message.text}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="chat-input">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default ChatArea;
